/* eslint-disable */
 // @ts-disable


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AboutBody": [
      "ArticleFeed",
      "ArticleSlider",
      "Banner",
      "BodyContent",
      "BowWave",
      "Breadcrumb",
      "CTA",
      "Collage",
      "CollageSecondary",
      "DynamicImage",
      "FaqIconCards",
      "Highlights",
      "ImageFullscreenSingle",
      "LinkBlocks",
      "MapGlobal",
      "MultiColumn",
      "NewsFeed",
      "Numbers",
      "ParallaxTiles",
      "Services",
      "StepByStep",
      "StoryTell",
      "StoryTellDeepDive",
      "TestimonialCards",
      "Testimonials",
      "TextWithImage",
      "TextWithVideo",
      "Tiles",
      "VideoPanoramic"
    ],
    "AboutHeader": [
      "HeaderPrimary",
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs",
      "HeaderVideo"
    ],
    "AlgoliaOverview": [
      "AlgoliaArticleOverview",
      "AlgoliaCharterProductOverview",
      "AlgoliaEventOverview",
      "AlgoliaInStockProductOverview",
      "AlgoliaNewsOverview",
      "AlgoliaProjectOverview",
      "AlgoliaShiprepairYardOverview",
      "AlgoliaUsedProductOverview"
    ],
    "AlgoliaOverviewWithSearch": [
      "AlgoliaArticleOverview",
      "AlgoliaNewsOverview"
    ],
    "AllIndexItems": [
      "ArticlesIndexItem",
      "EventIndexItem",
      "LocationIndexItem",
      "ProductIndexItem",
      "ProjectIndexItem",
      "UsedProductIndexItem"
    ],
    "ArticleBodyContent": [
      "BodyContent",
      "Image",
      "Video"
    ],
    "ArticleDetailBody": [
      "ArticleBody",
      "ArticleFeed",
      "Breadcrumb",
      "CTA",
      "Newsletter",
      "TextWithImage",
      "TextWithVideo"
    ],
    "ArticleHeader": [
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs",
      "HeaderSplit"
    ],
    "ArticleLongFormContent": [
      "BodyContent",
      "BowWave",
      "CTA",
      "Image",
      "Testimonials",
      "Video"
    ],
    "ArticleOverviewBody": [
      "AlgoliaArticleOverview",
      "Breadcrumb",
      "CTA",
      "Newsletter",
      "Stream",
      "Unknown"
    ],
    "ArticlePageContent": [
      "ArticleLongForm",
      "ArticleShortForm"
    ],
    "ArticleShortFormContent": [
      "BodyContent",
      "BowWave",
      "Breadcrumb",
      "Collage",
      "FaqIconCards",
      "Testimonials",
      "TextWithImage",
      "TextWithVideo"
    ],
    "BaseSlide": [
      "ArticleSlide",
      "ExpertiseSliderItem",
      "FullscreenSlide",
      "ProductSlide"
    ],
    "ContentBody": [
      "AlgoliaCharterProductOverview",
      "AlgoliaEventOverview",
      "AlgoliaGlobalSearch",
      "AlgoliaInStockProductOverview",
      "AlgoliaProjectOverview",
      "AlgoliaShiprepairYardOverview",
      "AlgoliaUsedProductOverview",
      "ArticleFeed",
      "ArticleSlider",
      "Banner",
      "BodyContent",
      "BowWave",
      "Breadcrumb",
      "CTA",
      "CampaignSmallForm",
      "Collage",
      "CollageSecondary",
      "ContactInfoCompany",
      "Contacts",
      "Downloads",
      "DynamicImage",
      "EventDetails",
      "EventFeed",
      "ExpertiseSlider",
      "FamilyModel",
      "FaqIconCards",
      "FullscreenSlider",
      "Gallery",
      "Highlights",
      "Image",
      "ImageFullscreenSingle",
      "IntegratedServices",
      "LinkBlocks",
      "MapGlobal",
      "MultiColumn",
      "NewsFeed",
      "Numbers",
      "ParallaxTiles",
      "PardotForm",
      "Partners",
      "ProductDetails",
      "ProductList",
      "ProductQuickView",
      "ProductSlider",
      "ProjectDetails",
      "QuickNav",
      "ServiceBanner",
      "Services",
      "StepByStep",
      "StickyNavigation",
      "StoryTell",
      "StoryTellDeepDive",
      "Stream",
      "StreamThumbnails",
      "Table",
      "TableComparison",
      "TableWithRows",
      "Tabs",
      "TabsFamily",
      "TestimonialCards",
      "Testimonials",
      "TextWithImage",
      "TextWithVideo",
      "Tiles",
      "Unknown",
      "UsedProductDetails",
      "Video",
      "VideoCenter",
      "VideoFeed",
      "VideoPanoramic"
    ],
    "ContentHeader": [
      "HeaderError",
      "HeaderHome",
      "HeaderPrimary",
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs",
      "HeaderSplit",
      "HeaderVideo",
      "Unknown"
    ],
    "DeepDiveItem": [
      "BodyContent",
      "BowWave",
      "Collage",
      "Image",
      "ImageFullscreen",
      "MultiColumn",
      "StoryTell",
      "StoryTellDeepDive",
      "TextWithImage",
      "TextWithVideo",
      "Video"
    ],
    "FamilyHeader": [
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs",
      "HeaderVideo"
    ],
    "FamilyPage": [
      "ContentPage",
      "Family"
    ],
    "FaqBody": [
      "BodyContent",
      "FaqIconCards"
    ],
    "FaqHeader": [
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs",
      "HeaderSplit",
      "HeaderVideo"
    ],
    "GalleryItemItems": [
      "GalleryItemImage",
      "GalleryItemVideo"
    ],
    "HeaderSecondaryBase": [
      "HeaderSecondary",
      "HeaderSecondaryDeepDive",
      "HeaderSecondaryNoImage",
      "HeaderSecondaryText",
      "HeaderSecondaryWithTabs"
    ],
    "IPage": [
      "ContentPage"
    ],
    "IPageComponent": [
      "AlgoliaArticleOverview",
      "AlgoliaCharterProductOverview",
      "AlgoliaEventOverview",
      "AlgoliaInStockProductOverview",
      "AlgoliaNewsOverview",
      "AlgoliaProjectOverview",
      "AlgoliaShiprepairYardOverview",
      "AlgoliaUsedProductOverview"
    ],
    "IPardotHandler": [
      "CampaignSmallForm",
      "ModalContactContent",
      "ModalDepartmentContactContent",
      "ModalDownloadContent",
      "ModalNewsletterContent",
      "ModalQuoteContent"
    ],
    "ITags": [
      "ArticlesIndexItemTags",
      "Tags"
    ],
    "InsightsBody": [
      "ArticleFeed",
      "BowWave",
      "Breadcrumb",
      "CTA",
      "NewsFeed",
      "VideoCenter"
    ],
    "IntegratedServicesHotspot": [
      "IntegratedServicesHotspotWithDetails",
      "IntegratedServicesHotspotWithHighlight"
    ],
    "LinkBlock": [
      "LinkBlockImage",
      "LinkBlockList"
    ],
    "NewsDetailBody": [
      "ArticleBody",
      "Breadcrumb",
      "CTA",
      "NewsBody",
      "NewsFeed",
      "Newsletter",
      "TextWithImage",
      "TextWithVideo"
    ],
    "NewsDetailBodyContent": [
      "BodyContent",
      "Image",
      "Video"
    ],
    "NewsOverviewBody": [
      "AlgoliaNewsOverview",
      "Breadcrumb",
      "CTA",
      "Newsletter",
      "StreamThumbnails",
      "Unknown"
    ],
    "PressBody": [
      "BrandFeed",
      "CTA",
      "CardFeedAlternative"
    ],
    "ProjectDetailsGalleryItemItems": [
      "ProjectDetailsGalleryItemImage",
      "ProjectDetailsGalleryItemVideo"
    ],
    "SiteNavigationSupportingItem": [
      "SiteNavigationBanner",
      "SiteNavigationQuickLinks"
    ],
    "Tab": [
      "TabsContacts",
      "TabsLinks"
    ],
    "VideoOverviewBody": [
      "CTA",
      "Newsletter",
      "VideoFeed",
      "VideoPanoramic"
    ]
  }
};
      export default result;
    