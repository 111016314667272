import { useMemo } from 'react'
import { ApolloClient, makeVar, NormalizedCacheObject } from '@apollo/client'
import { createApolloClient } from './apollo-client'

let apolloClient: ApolloClient<NormalizedCacheObject>

export const initializeApollo = (preview = false, initialState: any = null) => {
  const currentApolloClient = apolloClient ?? createApolloClient(preview)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = currentApolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    currentApolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return currentApolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = currentApolloClient

  return currentApolloClient
}

export const useApollo = (initialState: any) => {
  const store = useMemo(() => initializeApollo(false, initialState), [initialState])
  return store
}

export const reCaptcha = makeVar<string>(null)
export const mapKey = makeVar<string>(null)
export const showContactModal = makeVar<boolean>(false)
export const showDepartmentContactModal = makeVar<string>(undefined)
export const showDonationModal = makeVar<string>(undefined)
export const showDepartmentNewsletterModal = makeVar<string>(undefined)
export const showFeedbackModal = makeVar<boolean>(false)
export const showSurveyMonkeyModal = makeVar<boolean>(false)
export const showSurveyMonkeyPopupModal = makeVar<boolean>(false)
export const showMapModal = makeVar<boolean>(false)
export const showGlobalMapModal = makeVar<boolean>(false)
export const showNewsletterModal = makeVar<boolean>(false)
// Used to set the state of the menu e.g. if it is open or not.
export const toggleNav = makeVar<boolean>(false)
// The family page has the tabs to switch between views (overview / models).
// Once the tab component is scrolled out of view, a sticky navigation component will be shown.
// Since this is now nested deeper in the DOM we want the main navigation to know that it should not display on top.
export const showFamilyNav = makeVar<boolean>(false)
